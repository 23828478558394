import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectSession } from 'shared/reducers/sessionReducer';
import { fetchUnits } from 'shared/repositories/assetsRepository';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import { addNotification } from 'shared/actions/notificationsActions';
import { selectSubscriptions } from 'shared/reducers/subscriptionsSlice';
import { fetchEnterpriseObject } from 'shared/repositories/enterpriseObjectRepository';
import { editUserSubscriptions, fetchUserSubscriptions } from 'shared/actions/subscriptionsActions';

import AssetPage from 'single-asset/pages/AssetPage';
import { checkDecoderPermissions } from 'single-asset/helpers/singleAssetHelpers';
import { IP_COPY_SUCCESS_MESSAGE } from 'single-asset/constants/headerConstants';
import { ChartingContext } from 'context/ChartingContext';
import { Unit } from 'classes/unit';

const AssetPageContainer = ({ user }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { unitId } = params;
  const { charting } = useContext(ChartingContext);

  const session = useSelector(selectSession);
  const { groups } = useSelector(selectPermissions);
  const { fetchedSuccessfully: fetchedSubscriptions, subscriptions } = useSelector(selectSubscriptions);
  const hasDecoderAndIpAccess = checkDecoderPermissions(groups);

  const getSubscriptions = () => dispatch(fetchUserSubscriptions());
  const updateUserSubscriptions = (newSubscriptions) => dispatch(editUserSubscriptions(newSubscriptions));
  const addIpCopyToast = () => dispatch(addNotification({ type: 'success', message: IP_COPY_SUCCESS_MESSAGE }));

  const [units, setUnits] = useState([]);
  const [asset, setAsset] = useState(null);
  const [unitInfo, setUnitInfo] = useState(new Unit({ id: unitId }));
  const [unitsLoading, setUnitsLoading] = useState(false);
  const [enterpriseObject, setEnterpriseObject] = useState(null);
  const [enterpriseObjectLoading, setEnterpriseObjectLoading] = useState(false);

  const getUnits = async (filters = []) => {
    setUnitsLoading(true);

    try {
      const response = await fetchUnits({ accessToken: session?.token, body: filters });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      const filteredResponseData = responseData?.filter((entry) => entry.invalidated_at === null);

      setUnits(filteredResponseData);
    } catch (error) {
      console.error('Error fetching assets:', error);
    } finally {
      setUnitsLoading(false);
    }
  };

  const getEnterpriseObject = async (name) => {
    setEnterpriseObjectLoading(true);

    try {
      const response = await fetchEnterpriseObject({ accessToken: session?.token, body: { assetName: name } });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      const matchingAsset = responseData?.assets?.find((unit) => unit?.name === name);

      setEnterpriseObject(matchingAsset);
    } catch (error) {
      console.error('Error fetching enterprise objects:', error);
    } finally {
      setEnterpriseObjectLoading(false);
    }
  };

  const getFullUnitInfo = async () => {
    const fullUnit = await unitInfo.getCombinedInfo(session.token);
    setUnitInfo(fullUnit);
  };

  useEffect(() => {
    if (!unitInfo.id) {
      setUnitInfo(new Unit({ id: unitId }));
    }
    session?.token && getUnits();
    session?.token && getFullUnitInfo();
  }, [session, unitId]);

  useEffect(() => {
    if (!asset) {
      const matchingAssets = units?.filter((u) => u?.asset_name.replace(/\D/g, '') === params?.unitId);
      if (matchingAssets?.length) {
        // selects the unit that has the most recent last_synced date
        const lastCommunicatedAsset = matchingAssets.reduce((a, b) => (
          new Date(a.last_synced ?? null).getTime() > new Date(b.last_synced ?? null).getTime()
            ? a
            : b
        ));
        setAsset(lastCommunicatedAsset);
      }
    }

    if (asset && !enterpriseObject) {
      const currentEnterpriseObject = getEnterpriseObject(asset?.asset_name);
      currentEnterpriseObject && setEnterpriseObject(currentEnterpriseObject);
    }
  }, [units, params, asset]);

  return (
    <section>
      {enterpriseObjectLoading || unitsLoading || !asset || (charting?.layouts === undefined)
        ? (
          <div style={{
            zIndex: '200',
            margin: '40px',
            position: 'absolute',
          }}
          >
            <h3>Loading Unit View . . .</h3>
          </div>
        )
        : (
          <AssetPage
            user={user}
            asset={asset}
            unit={unitInfo}
            subscriptions={subscriptions}
            addIpCopyToast={addIpCopyToast}
            enterpriseObject={enterpriseObject}
            getSubscriptions={getSubscriptions}
            fetchedSubscriptions={fetchedSubscriptions}
            hasDecoderAndIpAccess={hasDecoderAndIpAccess}
            updateUserSubscriptions={updateUserSubscriptions}
          />
        )}
    </section>
  );
};

export default AssetPageContainer;
