import { URL_PREFIX } from 'shared/repositories/urls';

export type RecycleValveType = 'None' | 'Pneumatic' | 'Electronic';
export type CompressorValueType = 'None' | 'Poppet' | 'Slide';
export type ApplicationTypeType = 'Flowing Wellhead' | 'N/A' | 'Pipeline Booster' | 'Booster' | 'Wellhead' | 'Casing Drawdown' | 'Multi-Stream' | 'Special Application' | 'Gas Lift' | 'Plunger Lift' | 'Coal Bed Machine' | 'Flash Gas' | 'Vapor Recovery' | 'Backside Application' | 'CDP' | 'TriPlex Pump' | 'None';

interface OperatingArea {
  name?: string;
  netsuiteId?: number;
}

interface PollingInformation {
  interval?: number;
  communicationProtocol?: string;
  softwareMappingId?: string;
  tagMappingId?: string;
}

interface EdgeComputing {
  deviceType?: string;
  collectionMethod?: string;
  hmiVersion?: string;
  plcVersion?: string;
  hmiModel?: string;
  pollingInformation?: PollingInformation;
}

export interface Organization {
  id?: string;
  name?: string;
  netsuiteId?: number;
}

export interface Site {
  id?: string;
  name?: string;
  h2sPpm?: number | null;
  lastAct?: string;
  parentId?: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  h2sPresent?: boolean;
  addressCountry?: string | null;
  addressLocality?: string;
  addressAddressee?: string | null;
  addressPostalCode?: string;
  siteCentroidLatitude?: number | null;
  siteCentroidLongitude?: number | null;
  addressStreetAddress1?: string;
  addressStreetAddress2?: string;
  addressAdministrativeAreaLevel1?: string;
  addressAdministrativeAreaLevel2?: string | null;
  addressAdministrativeAreaLevel3?: string | null;
  addressAdministrativeAreaLevel4?: string | null;
}

export interface IUnit {
  id?: string,
  parentId?: string,
  version?: number,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  updatedBy?: string,
  deleted?: boolean,
  lastAct?: string,
  axilId?: number,
  netsuiteId?: number,
  skidId?: string,
  applicationType?: ApplicationTypeType,
  targetingMode?: string,
  packageModel?: string,
  assetName?: string,
  displayName?: string,
  driver?: string,
  unitProfileId?: string,
  modemId?: string,
  organizationId?: string,
  organizationInfo?: Organization,
  siteId?: string,
  siteInfo?: Site,
  deviceId?: string,
  customerOwned?: boolean,
  mechanicId?: string,
  operatingArea?: OperatingArea,
  recycleValve?: RecycleValveType,
  compressorValve?: string,
  edgeComputing?: EdgeComputing,
  shutIn?: boolean
}

export class Unit {
  id?: string;

  parentId?: string;

  version?: number;

  createdAt?: string;

  createdBy?: string;

  updatedAt?: string;

  updatedBy?: string;

  deleted?: boolean;

  lastAct?: string;

  axilId?: number;

  netsuiteId?: number;

  skidId?: string;

  applicationType?: ApplicationTypeType;

  targetingMode?: string;

  packageModel?: string;

  assetName?: string;

  displayName?: string;

  driver?: string;

  unitProfileId?: string;

  modemId?: string;

  organizationId?: string;

  organizationInfo?: Organization;

  siteId?: string;

  siteInfo?: Site;

  deviceId?: string;

  customerOwned?: boolean;

  mechanicId?: string;

  operatingArea?: OperatingArea;

  recycleValve?: RecycleValveType;

  compressorValve?: CompressorValueType;

  edgeComputing?: EdgeComputing;

  shutIn?: boolean;

  constructor(params: IUnit) {
    Object.assign(this, params);
  }

  async getUnit(authToken: string) {
    if (!this.id) return null;
    try {
      const res = await fetch(`https://${URL_PREFIX}queries.api.axil.ai/v1/units/find?unitId=${this.id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const unitResults = await res.json();
      const unit = unitResults.find((u: IUnit) => u.id === this.id);
      Object.assign(this, unit);
      return this;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getSiteInfo(siteId: string, authToken: string) {
    if (this.siteInfo) return this.siteInfo;
    try {
      const res = await fetch(`https://${URL_PREFIX}sites.api.axil.ai/sites/${siteId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const siteResult = await res.json();
      this.siteInfo = siteResult;
      return this;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getOrganizationInfo(orgId: string, authToken: string) {
    if (this.organizationInfo) return this.organizationInfo;
    try {
      const res = await fetch(`https://${URL_PREFIX}orgs.api.axil.ai/orgs/${orgId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const orgResult = await res.json();
      this.organizationInfo = orgResult;
      return this;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getCombinedInfo(authToken: string) {
    await this.getUnit(authToken);
    await this.getSiteInfo(this.siteId as string, authToken);
    await this.getOrganizationInfo(this.organizationId as string, authToken);
    return this;
  }

  getOwnership() {
    if (this.customerOwned === undefined) return 'Unknown Ownership';
    return this.customerOwned ? 'Customer Owned' : 'Rental Units';
  }
}
