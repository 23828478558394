/* eslint-disable consistent-return */
const ENV_URL = process.env.REACT_APP_BUILD_ENV === 'production'
  ? 'https://'
  : 'https://dev-';

const NOTES_BASE_URL = `${ENV_URL}notes.api.axil.ai`;

export const deleteNote = async ({
  noteId,
  noteVersion,
  accessToken,
}) => {
  const FORMATTED_URL = `${NOTES_BASE_URL}/notes/${noteId}`;

  try {
    const response = await fetch(FORMATTED_URL, {
      method: 'DELETE',
      headers: {
        'If-Match': noteVersion,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching notes: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching notes.');
  }
};

export const updateNoteVisibility = async ({
  body,
  noteId,
  version,
  accessToken,
}) => {
  const FORMATTED_URL = `${NOTES_BASE_URL}/notes/${noteId}/visibility`;

  try {
    const response = await fetch(FORMATTED_URL, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'If-Match': version,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error updating note: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error updating note.');
  }
};

export const updateNote = async ({
  body,
  noteId,
  version,
  accessToken,
}) => {
  const FORMATTED_URL = `${NOTES_BASE_URL}/notes/${noteId}/content`;

  try {
    const response = await fetch(FORMATTED_URL, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'If-Match': version,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error updating note: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error updating note.');
  }
};

export const createNote = async ({
  body,
  accessToken,
}) => {
  const FORMATTED_URL = `${NOTES_BASE_URL}/notes`;

  try {
    const response = await fetch(FORMATTED_URL, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error creating note: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error creating note.');
  }
};

export const getNotes = async ({
  unitId,
  accessToken,
}) => {
  const FORMATTED_URL = `${NOTES_BASE_URL}/notes/${unitId}/notes`;

  try {
    const response = await fetch(FORMATTED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching notes: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching notes.');
  }
};
