import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NewReportCreation from 'reporting/components/new-report/NewReportCreation';
import ScheduledReportSection from 'reporting/components/scheduled-reports/ScheduledReportSection';
import { Report, ReportOrganization, ScheduledReportData } from 'reporting/pages/ReportingPage';

const ReportingRoutes = ({
  orgs,
  onSubmit,
  availableReports,
  reportBeingEdited,
  setReportBeingEdited,
}: {
  orgs: ReportOrganization[];
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: {
    oneTimeReport: boolean;
    requestData: {
      [key: string]: string | number;
    };
    reportName: string;
  }) => void;
  availableReports: Report[];
  reportBeingEdited: ScheduledReportData | null;
  // eslint-disable-next-line no-unused-vars
  setReportBeingEdited: React.Dispatch<React.SetStateAction<null>>;
}) => (
  <Routes>
    <Route
      path="/"
      element={(
        <NewReportCreation
          orgs={orgs}
          onSubmit={onSubmit}
          availableReports={availableReports}
          reportBeingEdited={reportBeingEdited}
          setReportBeingEdited={setReportBeingEdited}
        />
    )}
    />
    <Route
      path="/scheduled"
      element={(
        <ScheduledReportSection
          organizations={orgs}
          setReportBeingEdited={setReportBeingEdited}
        />
      )}
    />
  </Routes>
);

export default ReportingRoutes;
