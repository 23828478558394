import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { selectSession } from 'shared/reducers/sessionReducer';

import {
  Icon,
  Input,
  Modal,
  Button,
  Checkbox,
} from '@flogistix/flo-ui';

import {
  getNotes,
  createNote,
  deleteNote,
} from 'shared/repositories/notesRepository';
import { addNotification } from 'shared/actions/notificationsActions';

import Note from 'single-asset/components/notes/Note';
import { sortNotes } from 'single-asset/helpers/notesHelpers';
import { INoteData } from 'single-asset/constants/notesConstants';
import DisplayedNote from 'single-asset/components/notes/DisplayedNote';

import './NotesPage.scss';

interface NotesPageProps {
  ordId: number,
  assetName: string,
  externalUser: boolean
}

const NotesPage: React.FC<NotesPageProps> = ({ ordId, assetName, externalUser }) => {
  const dispatch = useDispatch();
  const currentSession = useSelector(selectSession);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState<INoteData | null>(null);

  const [notes, setNotes] = useState<INoteData[]>([]);
  const [showAddNote, setShowAddNote] = useState(false);
  const [newNoteMarkdown, setNewNoteMarkdown] = useState('');
  const [newNoteIsPublic, setNewNoteIsPublic] = useState(false);
  const [notesDataPending, setNotesDataPending] = useState(false);
  const [displayedNotes, setDisplayedNotes] = useState<INoteData[]>([]);
  const [currentSortOrder, setCurrentSortOrder] = useState('descending');

  const deleteNoteFromUnit = async () => {
    setNotesDataPending(true);

    try {
      const response = await deleteNote({
        noteId: noteToDelete?.id,
        accessToken: currentSession?.token,
        noteVersion: noteToDelete?.version,
      });

      if (!response?.ok) {
        throw new Error(`Create failed with status: ${response?.status}`);
      }

      getNotesForUnit();
      dispatch(addNotification({ type: 'success', message: 'You successfully deleted a note.' }));
    } catch (error) {
      dispatch(addNotification({ type: 'error', message: 'Note failed to delete. Please try again.' }));
    } finally {
      setNotesDataPending(false);
      setShowDeleteModal(false);
      setNewNoteMarkdown('');
      setShowAddNote(false);
    }
  };

  const createNoteForUnit = async () => {
    setNotesDataPending(true);

    try {
      const response = await createNote({
        accessToken: currentSession?.token,
        body: {
          orgId: ordId,
          parentId: assetName,
          content: newNoteMarkdown,
          visibility: newNoteIsPublic ? 'External' : 'Internal',
        },
      });

      if (!response?.ok) {
        throw new Error(`Create failed with status: ${response?.status}`);
      }

      getNotesForUnit();
      dispatch(addNotification({ type: 'success', message: 'You successfully created a note.' }));
    } catch (error) {
      dispatch(addNotification({ type: 'error', message: 'Note failed to be created, please try again.' }));
      console.error('Error creating note:', error);
    } finally {
      setNotesDataPending(false);
      setNewNoteMarkdown('');
      setShowAddNote(false);
    }
  };

  const getNotesForUnit = async () => {
    setNotesDataPending(true);

    try {
      const response = await getNotes({
        unitId: assetName,
        accessToken: currentSession?.token,
      });

      if (!response?.ok) {
        throw new Error(`Fetch failed with status: ${response?.status}`);
      }

      const responseData = await response?.json();

      setNotes(sortNotes(responseData, currentSortOrder));
    } catch (error) {
      dispatch(addNotification({ type: 'error', message: 'There was an error fetching notes, please try again.' }));
      console.error('Error fetching notes:', error);
    } finally {
      setNotesDataPending(false);
    }
  };

  const toggleSortOrder = () => {
    const newSortOrder = currentSortOrder === 'descending' ? 'ascending' : 'descending';
    setCurrentSortOrder(newSortOrder);
    handleSortNotes(newSortOrder);
  };

  const handleSortNotes = (sortOrder = currentSortOrder) => {
    const newPublicNotes = notes?.filter((entry) => entry?.visibility === 'External');

    if (externalUser) {
      const sortedPublicNotes = sortNotes(newPublicNotes, sortOrder);
      setDisplayedNotes(sortedPublicNotes);
    } else {
      const sortedNotes = sortNotes(notes, sortOrder);
      setDisplayedNotes(sortedNotes);
    }
  };

  const cancelNoteUpdates = () => {
    setShowAddNote(false);
    setNewNoteMarkdown('');
    setNewNoteIsPublic(false);
  };

  useEffect(() => {
    handleSortNotes();
  }, [notes, externalUser, currentSortOrder]);

  useEffect(() => {
    if (currentSession?.token && assetName) {
      getNotesForUnit();
    }
  }, [currentSession, assetName]);

  return (
    <section className="notes-page-container">
      {
        notesDataPending
          ? <h1> Loading Notes data... </h1>
          : (
            <section className="notes-page">
              {
                !externalUser && (
                  <div className="notes-page-header">
                    <h2>Create a new note</h2>
                    <p>Add a new note to keep track of insights for this unit.</p>
                  </div>
                )
              }
              {
                !externalUser && showAddNote && (
                  <div className="add-note-section">
                    <Note
                      markdown={newNoteMarkdown}
                      handleMarkdown={setNewNoteMarkdown}
                    />
                    <section className="add-note-section-actions">
                      <section className="public-checkbox-container">
                        <Checkbox
                          checked={newNoteIsPublic}
                          onChecked={setNewNoteIsPublic}
                        />
                        <p>Public</p>
                        <span>Allow non-Flogistix users to view this note.</span>
                      </section>
                      <div className="edit-note-buttons">
                        <Button
                          fixedWidth="112px"
                          variation="shadow-outline"
                          onClick={cancelNoteUpdates}
                        >
                          Cancel
                        </Button>
                        <Button
                          fixedWidth="112px"
                          onClick={createNoteForUnit}
                          disabled={!newNoteMarkdown?.length}
                        >
                          Save
                        </Button>
                      </div>
                    </section>
                  </div>
                )
              }
              {
                !externalUser && !showAddNote && (
                  <div className="add-note-section">
                    <Input onClick={() => setShowAddNote(true)} placeholder="Write a note..." />
                  </div>
                )
              }
              <div className="notes-display-section">
                {
                  displayedNotes?.length
                    ? (
                      <div className="notes-display-section-header">
                        <h1>Activity</h1>
                        <div
                          onClick={toggleSortOrder}
                          className="notes-display-section-header-sort"
                        >
                          <span>
                            {
                              currentSortOrder === 'descending'
                                ? 'Newest first'
                                : 'Oldest first'
                            }
                          </span>
                          <Icon iconName="Filter" width={16} />
                          <Icon
                            width={16}
                            iconName={
                            currentSortOrder === 'descending'
                              ? 'ArrowUp'
                              : 'ArrowDown'
                            }
                          />
                        </div>
                      </div>
                    )
                    : <span />
                }
                {
                  displayedNotes?.length
                    ? displayedNotes?.map((note) => (
                      <DisplayedNote
                        note={note}
                        key={uuid()}
                        externalUser={externalUser}
                        getNotesForUnit={getNotesForUnit}
                        setNoteToDelete={setNoteToDelete}
                        userEmail={currentSession?.user?.email}
                        showDeleteModal={() => setShowDeleteModal(true)}
                      />
                    ))
                    : <span />
                }
                {
                  !displayedNotes?.length && externalUser
                    ? (
                      <section className="no-notes-section">
                        <div className="no-notes-section-content">
                          <h1>No Activity Yet</h1>
                          <p>There are no notes or updates for this unit. When activity is logged, you’ll be able to view it here to stay informed on its status.</p>
                        </div>
                      </section>
                    )
                    : <span />
                }
              </div>
            </section>
          )
      }
      <Modal
        variant="delete"
        cancelText="Cancel"
        title="Delete note"
        confirmText="Confirm"
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        subtitle="Are you sure you want to delete this note? This action cannot be undone."
        actions={{
          loading: notesDataPending,
          onConfirm: () => deleteNoteFromUnit(),
          onCancel: () => setShowDeleteModal(false),
        }}
      />
    </section>
  );
};

export default NotesPage;
