import React from 'react';

import './LoadingSpinner.scss';

const LoadingSpinner = ({ size = 20, color = '#BCBCBC', width = 2 }) => {
  const containerStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  const spinnerStyle = {
    borderTopColor: color,
    borderWidth: `${width}px`,
  };

  return (
    <div className="flux-spinner-container" style={containerStyle}>
      <div className="flux-spinner" style={spinnerStyle} />
    </div>
  );
};

export default LoadingSpinner;
