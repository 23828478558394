/* eslint-disable react/jsx-no-useless-fragment */
import {
  Spin,
  Input,
  Modal,
  Select,
} from 'antd';
import { Pill, Icon } from '@flogistix/flo-ui';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

import {
  checkIpValidity,
  connectNewIpAddress,
} from 'shared/repositories/enterpriseObjectRepository';
import { selectSession } from 'shared/reducers/sessionReducer';
import { ASSET_TYPE_NAMES } from 'shared/constants/altitudeConstants';

import {
  IP_PORT_OPTIONS,
  INVALID_IP_ERROR_MESSAGE,
} from 'single-asset/constants/headerConstants';
import { isValidIpAddress } from 'single-asset/helpers/singleAssetHelpers';
import { greenAssetStatusTypes, yellowAssetStatusTypes } from 'shared/constants/assetConstants';
import { getConnectionPillVariant, getFormattedElapsedTime } from 'shared/helpers/assetHelpers';
import FluxTooltip from 'shared/components/FluxTooltip';

import './UnitHeader.scss';

const UnitHeader = ({
  unit,
  coordinates,
  addIpCopyToast,
  unitProfilesLink,
  hasEditIpAccess,
  enterpriseObject,
  setNotificationModalOpen,
}) => {
  const defaultSubmissionErrors = { message: '', ipAddress: false };

  const currentSession = useSelector(selectSession);

  const [loading, setLoading] = useState(false);
  const [override, setOverride] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [isEditingIp, setIsEditingIp] = useState(false);
  const [assetIp, setAssetIp] = useState(enterpriseObject?.ipAddress);
  const [submissionErrors, setSubmissionErrors] = useState(defaultSubmissionErrors);
  const [assetPort, setAssetPort] = useState(enterpriseObject?.ipInfo?.port ?? 9600);

  // eslint-disable-next-line consistent-return
  const submitIpEdit = async () => {
    if (!isValidIpAddress(assetIp)) {
      return setSubmissionErrors({
        ipAddress: true,
        message: '',
      });
    }

    setLoading(true);

    try {
      const validityCheck = await checkIpValidity({
        accessToken: currentSession?.token,
        body: {
          orgId: unit.orgId,
          siteId: unit.siteId,
          assetId: unit.axilId,
          ipAddress: `${assetIp}`,
          ipPort: `${assetPort}`,
        },
      });

      const parsedResponse = await validityCheck.json();

      if (parsedResponse?.valid || validityCheck.status === 408) return confirmIpUpdate();
    } catch (error) {
      setOverride(true);

      setSubmissionErrors({
        ipAddress: false,
        message: 'IP Address connection error. IP may be in use on another unit.',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmIpUpdate = async () => {
    setLoading(true);
    setOverride(false);

    try {
      const { orgId, siteId, axilId } = unit;

      const body = {
        eventType: 'edit',
        dataType: 'IpAddress',
        time: Date.now().toString(),
        source: 'Axil.IpAddressChange',
        data: {
          identifiers: {
            altitude: `orgs/${orgId}/sites/${siteId}/assets/${axilId}`,
          },
          changes: {
            ipAddress: `${assetIp}`,
            port: `${assetPort}`,
          },
        },
      };

      const result = await connectNewIpAddress({ accessToken: currentSession?.token, body });

      if (result.ok || result.status === 202) {
        setIsEditingIp(false);
        setSubmissionErrors(defaultSubmissionErrors);
      }
    } catch (error) {
      setSubmissionErrors({ ipAddress: false, message: 'Error updating IP Address. If this problem persists, please contact support.' });
    } finally {
      setLoading(false);
    }
  };

  const handleIpCopy = () => {
    if (assetIp) {
      navigator.clipboard.writeText(assetIp);
      addIpCopyToast();
    }
  };

  const handleCancelIpEdit = () => {
    setOverride(false);
    setIsEditingIp(false);
    setAssetIp(enterpriseObject?.ipAddress);
    setSubmissionErrors(defaultSubmissionErrors);
    setAssetPort(enterpriseObject?.ipInfo?.port ?? 9600);
  };

  const handleNavigate = () => {
    if (!coordinates) return;
    window.open(`https://www.google.com/maps/place/${coordinates?.lat},${coordinates?.lng}`);
  };

  const getStatusPillColor = () => {
    if (greenAssetStatusTypes.includes(unit?.compressorStatus)) return 'green';
    if (yellowAssetStatusTypes.includes(unit?.compressorStatus)) return 'yellow';
    return 'red';
  };

  const getSitePillVariation = (ppm) => {
    if (ppm === undefined || ppm === null) return 'filter';
    if (ppm < 100) return 'green';
    return 'red';
  };

  const IconRow = ({ row }) => (
    <div className={`unit-header--${row}-row--icons`}>
      <FluxTooltip
        title={enterpriseObject?.mechanic?.name ?? 'Unknown Mechanic'}
        showInfoCircle={false}
        description={
              enterpriseObject?.mechanic?.phone
                ? (<a href={`tel:${enterpriseObject?.mechanic?.phone}`}>{enterpriseObject?.mechanic?.phone}</a>)
                : 'Unknown Mechanic Phone Number'
            }
        tooltip={(
          <span><Icon iconName="Phone" /></span>
            )}
      />
      <span onClick={handleNavigate}>
        <Icon
          iconName="NavigationPointer"
          className={!coordinates ? 'disabled-icon' : ''}
        />
      </span>
      <span onClick={() => window.open(unitProfilesLink)}>
        <Icon
          iconName="Folder"
          className={!unitProfilesLink ? 'disabled-icon' : ''}
        />
      </span>
      <span onClick={() => setNotificationModalOpen(true)}>
        <Icon
          iconName="Notification"
        />
      </span>
    </div>
  );

  useEffect(() => {
    setAssetIp(enterpriseObject?.ipAddress);
    setAssetPort(enterpriseObject?.ipInfo?.port ?? 9600);
  }, [enterpriseObject]);

  return (
    <div className="unit-header">
      <div className="unit-header--top-row">
        <div className="unit-header--top-row--asset-name">
          {unit?.displayName}
        </div>
        <IconRow row="top" />
      </div>
      <div className="unit-header--unit-details">
        <div>{ASSET_TYPE_NAMES[unit?.driver] ?? 'Unknown Asset Type'}</div>
        <div className="unit-header--unit-details--separator">•</div>
        <div>{unit?.packageModel ?? 'Unknown Asset Model'}</div>
        <div className="unit-header--unit-details--separator">•</div>
        <div>{unit?.getOwnership()}</div>
      </div>
      <Pill
        label={unit?.organizationInfo?.name ?? 'Unknown Company'}
        variation="gray"
        className="company-pill"
      />
      <Pill
        label={unit?.siteInfo?.name ?? 'Unknown Lease'}
        variation="gray"
      />
      <Pill
        label={unit?.operatingArea?.name ?? 'Unknown Area'}
        variation="gray"
      />
      <div className="unit-header--bottom-row">
        <div className="unit-header--bottom-row--stats">
          {
            unit?.shutIn ? (
              <Pill
                label="MA: N/A"
                variation="filter"
              />
            ) : (
              <Pill
                label={unit?.thirtyDayMechanicalAvailability ? `MA: ${unit.thirtyDayMechanicalAvailability}%` : 'MA: N/A'}
                variation={(unit?.thirtyDayMechanicalAvailability && unit?.thirtyDayMechanicalAvailability >= 98 ? 'green' : 'red') || 'gray'}
              />
            )
          }
          {
            unit?.shutIn ? (
              <Pill
                label="Status: Shut-In"
                variation="filter"
              />
            ) : (
              <Pill
                label={unit?.compressorStatus ? `Status: ${unit?.compressorStatus}` : 'Status: N/A'}
                variation={(unit?.compressorStatus && getStatusPillColor()) || 'gray'}
              />
            )
          }
          { unit?.siteInfo?.h2s?.location && (
            <Pill
              label={`H2S: ${unit?.siteInfo?.h2s?.ppm ?? 'N/A'} ppm`}
              variation={getSitePillVariation(unit?.siteInfo?.h2s?.ppm)}
            />
          )}
          <Pill
            label={unit?.lastCommunicated ? `Last Communicated: ${getFormattedElapsedTime(unit?.lastCommunicated)}` : 'Last Communicated: N/A'}
            variation={unit?.lastCommunicated ? getConnectionPillVariant(unit?.lastCommunicated) : 'gray'}
          />
        </div>
        <IconRow row="bottom" />
        <div className="unit-header--bottom-row--edit-ip">
          <div className="unit-header--bottom-row--edit-ip--info">
            <div
              className="unit-header--bottom-row--edit-ip--info--ip"
              onClick={handleIpCopy}
            >
              <span className="label">IP:</span>{(assetIp || enterpriseObject?.ipAddress) ?? 'Unknown'}
            </div>
            <div className="unit-header--bottom-row--edit-ip--info--port"><span className="label">Port:</span>{assetPort ?? 'Unknown'}</div>
          </div>
          <button
            type="button"
            onClick={() => setIsEditingIp(true)}
            disabled={!hasEditIpAccess}
          >
            <p>Edit</p>
          </button>
        </div>
      </div>
      <Modal
        centered
        footer={[]}
        title="Edit IP"
        open={isEditingIp}
        className="edit-ip-modal"
        onCancel={handleCancelIpEdit}
        wrapClassName="edit-ip-modal-dialog"
      >
        <label htmlFor="ipEditInput" className="edit-input-label">
          IP Address
        </label>
        <Input
          size="large"
          name="ipEditInput"
          value={assetIp ?? ''}
          allowClear={{ clearIcon: <></> }}
          placeholder={assetIp ?? 'IP Address'}
          status={submissionErrors?.ipAddress && 'error'}
          onChange={({ target: { value } }) => setAssetIp(value)}
        />
        {submissionErrors.ipAddress && (
          <p className="submission-error">
            {INVALID_IP_ERROR_MESSAGE}
          </p>
        )}
        <p htmlFor="ipPortSelect" className="edit-input-label">
          Port
        </p>
        <Select
          size="large"
          value={assetPort}
          name="ipPortSelect"
          defaultValue={assetPort}
          options={IP_PORT_OPTIONS}
          onSelect={(value) => setAssetPort(value)}
          onDropdownVisibleChange={() => setSelectOpen(!selectOpen)}
          suffixIcon={!selectOpen ? <IoChevronDown size={28} /> : <IoChevronUp size={28} />}
        />
        {submissionErrors.message.length > 0 && (
          <p className="submission-error">
            {submissionErrors.message}
          </p>
        )}
        {((assetIp !== enterpriseObject?.ipAddress) || (assetPort !== enterpriseObject?.ipInfo?.port))
          ? (
            <div className="button-group">
              {
                    !loading ? (
                      <>
                        <button onClick={handleCancelIpEdit}>Cancel</button>
                        <button onClick={override ? confirmIpUpdate : submitIpEdit}>
                          {
                            override ? 'Override and Save' : 'Save'
                          }
                        </button>
                      </>
                    ) : <Spin />
                  }
            </div>
          )
          : null}
      </Modal>
    </div>

  );
};

export default UnitHeader;
