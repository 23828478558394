import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs, Tab, InfoBanner } from '@flogistix/flo-ui';

import { selectSession } from 'shared/reducers/sessionReducer';
import { isUserExternal } from 'shared/helpers/permissionHelpers';
import { checkDigitalTwinExistence } from 'shared/repositories/assetsRepository';
import { getUnitProfileInfo, getUnitProfileLink } from 'shared/repositories/moreInfoStatsRepository';

import UnitHeader from 'single-asset/components/asset/UnitHeader';
import AssetRoutes from 'single-asset/components/asset/AssetRoutes';
import NotificationModal from 'single-asset/components/asset/NotificationModal';
import { coordinateTags, singleAssetRouteNames } from 'single-asset/constants/singleAssetConstants';
import { generateUnitProfilesLink, sharedRequestBody } from 'single-asset/helpers/singleAssetHelpers';

import './AssetPage.scss';

const AssetPage = ({
  user,
  asset,
  unit,
  subscriptions,
  addIpCopyToast,
  hasDecoderAndIpAccess,
  getSubscriptions,
  enterpriseObject,
  fetchedSubscriptions,
  updateUserSubscriptions,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navPath = pathname.split('/').at(-1);

  const currentSession = useSelector(selectSession);

  const [coordinates, setCoordinates] = useState(null);
  const [unitProfilesLink, setUnitProfilesLink] = useState(null);
  const [digitalTwinExistence, setDigitalTwinExistence] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  const externalUser = isUserExternal(user?.email);

  const getUnitProfilesLink = async () => {
    try {
      const response = await getUnitProfileLink({
        accessToken: currentSession?.token,
        netsuiteId: enterpriseObject?.netsuiteId,
      });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      const currentLink = generateUnitProfilesLink(responseData?.id);

      setUnitProfilesLink(currentLink);
    } catch (error) {
      console.error('Error unit profile link data:', error);
    }
  };

  const getCoordinates = async () => {
    try {
      const response = await getUnitProfileInfo({
        accessToken: currentSession?.token,
        body: {
          tags: coordinateTags,
          ...sharedRequestBody(asset),
        },
      });
      const [responseData] = await response.json();
      const { lat, lon } = responseData;
      if (lat && lon) setCoordinates({ lat, lng: lon });
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const closeNotificationModal = () => setNotificationModalOpen(false);

  useEffect(() => {
    if (currentSession?.token) {
      enterpriseObject && getUnitProfilesLink();
      unit && checkDigitalTwinExistence(unit.id, setDigitalTwinExistence);
      asset && getCoordinates();
    }
  }, [currentSession, asset, unit, enterpriseObject]);

  useEffect(() => {
    !fetchedSubscriptions && getSubscriptions();
  }, [fetchedSubscriptions]);

  return (
    <div className={`asset-page ${unit?.shutIn ? 'shutin' : ''}`}>
      <div className="asset-page--spacer" />
      {
        unit?.shutIn && (
          <InfoBanner
            className="shutin-banner"
            title="This unit is shut-in."
            subtitle="This means it is currently not operating."
          />
        )
      }
      {
        unit?.displayName && (
          <UnitHeader
            unit={unit}
            coordinates={coordinates}
            addIpCopyToast={addIpCopyToast}
            enterpriseObject={enterpriseObject}
            unitProfilesLink={unitProfilesLink}
            hasEditIpAccess={hasDecoderAndIpAccess}
            setNotificationModalOpen={setNotificationModalOpen}
          />
        )
      }
      <Tabs
        color="black"
        headerSize="h5"
        defaultActiveKey={Number.isNaN(parseInt(navPath))
          ? navPath
          : 'telemetry'}
      >
        <Tab
          title="Telemetry"
          eventKey="telemetry"
          onClick={() => navigate(singleAssetRouteNames.TELEMETRY)}
        />
        <Tab
          title="Events"
          eventKey="events"
          onClick={() => navigate(singleAssetRouteNames.EVENTS)}
        />
        <Tab
          title="Work Orders"
          eventKey="work-orders"
          onClick={() => navigate(singleAssetRouteNames.WORK_ORDERS)}
        />
        {
          hasDecoderAndIpAccess && (
            <Tab
              title="Decoder"
              eventKey="decoder"
              onClick={() => navigate(singleAssetRouteNames.DECODER)}
            />
          )
        }
        {
          !externalUser && (
            <Tab
              title="Emissions Calculator"
              eventKey="emissions-calculator"
              onClick={() => navigate(singleAssetRouteNames.EMISSIONS_CALC)}
            />
          )
        }
        <Tab
          title="Notes"
          eventKey="notes"
          onClick={() => navigate(singleAssetRouteNames.NOTES)}
        />
        <Tab
          title="More Information"
          eventKey="more-information"
          onClick={() => navigate(singleAssetRouteNames.MORE_INFORMATION)}
        />
        {
          digitalTwinExistence && (
            <Tab
              eventKey="digital-twin"
              title="3D Digital Twin"
              onClick={() => navigate(singleAssetRouteNames.DIGITAL_TWIN)}
            />
          )
        }
      </Tabs>
      {
        unit && (
          <AssetRoutes
            unit={unit}
            asset={asset}
            externalUser={externalUser}
            enterpriseObject={enterpriseObject}
          />
        )
      }
      <NotificationModal
        asset={asset}
        subscriptions={subscriptions}
        isOpen={notificationModalOpen}
        closeModal={closeNotificationModal}
        updateSubscriptions={updateUserSubscriptions}
      />
    </div>
  );
};

export default AssetPage;
