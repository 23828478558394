/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MDXEditor } from '@mdxeditor/editor';
import { Pill, Button, Checkbox } from '@flogistix/flo-ui';

import { selectSession } from 'shared/reducers/sessionReducer';
import { updateNote, updateNoteVisibility } from 'shared/repositories/notesRepository';
import { humanizeDate } from 'shared/helpers/emissionsHelpers';
import { addNotification } from 'shared/actions/notificationsActions';

import Note from 'single-asset/components/notes/Note';
import { INoteData } from 'single-asset/constants/notesConstants';

import './DisplayedNotes.scss';

interface DisplayedNoteProps {
  note: INoteData;
  userEmail: string;
  externalUser: boolean;
  getNotesForUnit: () => void;
  showDeleteModal: () => void;
  setNoteToDelete: (note: INoteData) => void;
}

const DisplayNote: React.FC<DisplayedNoteProps> = ({
  note,
  userEmail,
  externalUser,
  getNotesForUnit,
  showDeleteModal,
  setNoteToDelete,
}) => {
  const dispatch = useDispatch();
  const currentSession = useSelector(selectSession);

  const [noteVersion, setNoteVersion] = useState(note?.version);

  const [showEditNote, setShowEditNote] = useState(false);
  const [editedNoteMarkdown, setEditedNoteMarkdown] = useState(note?.content);
  const [newNoteIsPublic, setNewNoteIsPublic] = useState(note?.visibility === 'External');

  const handleNoteUpdates = async () => {
    let currentVersion = noteVersion;

    if (editedNoteMarkdown !== note?.content) {
      currentVersion = await updateUnitNoteContent(currentVersion);
    }

    if (newNoteIsPublic !== (note?.visibility === 'External')) {
      currentVersion = await updateUnitNoteVisibility(currentVersion);
    }

    setNoteVersion(currentVersion);
  };

  const updateUnitNoteContent = async (version: number) => {
    try {
      const response = await updateNote({
        version,
        noteId: note?.id,
        accessToken: currentSession?.token,
        body: { content: editedNoteMarkdown },
      });

      if (!response?.ok) {
        throw new Error(`Update failed with status: ${response?.status}`);
      }

      getNotesForUnit();
      dispatch(addNotification({ type: 'success', message: 'You successfully updated a note.' }));
      return version + 1;
    } catch (error) {
      dispatch(addNotification({ type: 'error', message: 'Note failed to be updated, please try again.' }));
      console.error('Error updating note:', error);
      return version;
    }
  };

  const updateUnitNoteVisibility = async (version: number) => {
    try {
      const response = await updateNoteVisibility({
        version,
        noteId: note?.id,
        accessToken: currentSession?.token,
        body: { visibility: newNoteIsPublic ? 'External' : 'Internal' },
      });

      if (!response?.ok) {
        throw new Error(`Update failed with status: ${response?.status}`);
      }

      getNotesForUnit();
      dispatch(addNotification({ type: 'success', message: 'You successfully updated a note.' }));
      return version + 1;
    } catch (error) {
      dispatch(addNotification({ type: 'error', message: 'Note failed to be updated, please try again.' }));
      console.error('Error updating note:', error);
      return version;
    }
  };

  const cancelNoteUpdates = () => {
    setShowEditNote(false);
    setEditedNoteMarkdown(note?.content);
    setNewNoteIsPublic(note?.visibility === 'External');
  };

  const handleDeleteNote = (noteToDelete: any) => {
    showDeleteModal();
    setNoteToDelete(noteToDelete);
  };

  return (
    <div className="display-note">
      {
        !showEditNote && (
          <section className="display-note-header">
            <h2> {note?.createdBy} </h2>
            <span>
              {
                humanizeDate(new Date(note?.updatedAt))
                || humanizeDate(new Date(note?.createdAt))
              }
            </span>
            {
              note?.version > 0
                && (
                  <>
                    <span className="circle" />
                    <span style={{ color: '#98A2B3' }}>Edited</span>
                  </>
                )
            }
          </section>
        )
      }
      <section className="display-note-body">
        {
          showEditNote
            ? (
              <>
                <Note
                  markdown={editedNoteMarkdown}
                  handleMarkdown={setEditedNoteMarkdown}
                />
                <br />
                <section className="public-checkbox-container">
                  <Checkbox
                    checked={newNoteIsPublic}
                    onChecked={setNewNoteIsPublic}
                  />
                  <p>Public</p>
                  <span>Allow non-Flogistix users to view this note.</span>
                </section>
                <div className="edit-note-buttons">
                  <Button
                    fixedWidth="112px"
                    variation="shadow-outline"
                    onClick={cancelNoteUpdates}
                  >
                    Cancel
                  </Button>
                  <Button
                    fixedWidth="112px"
                    onClick={handleNoteUpdates}
                    disabled={!editedNoteMarkdown?.length}
                  >
                    Save
                  </Button>
                </div>
              </>
            )
            : <MDXEditor markdown={note?.content} readOnly />
        }
      </section>
      {
        !externalUser && !showEditNote && (
          <section className="display-note-button-row">
            {
              note?.createdBy === userEmail && (
                <>
                  <span
                    className="text-button"
                    onClick={() => setShowEditNote(true)}
                  >
                    Edit
                  </span>
                  <span className="circle" />
                  <span
                    className="text-button"
                    onClick={() => handleDeleteNote(note)}
                  >
                    Delete
                  </span>
                </>
              )
            }
            <Pill
              variation={note?.visibility === 'Internal' ? 'gray' : 'blue'}
              label={note?.visibility === 'Internal' ? 'Internal-Only' : 'Public'}
            />
          </section>
        )
      }
    </div>
  );
};

export default DisplayNote;
