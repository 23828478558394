import { Route, Routes } from 'react-router-dom';

import EventsPage from 'single-asset/pages/EventsPage';
import DigitalTwin from 'single-asset/pages/DigitalTwin';
import TelemetryPage from 'single-asset/pages/TelemetryPage';
import WorkOrdersPage from 'single-asset/pages/WorkOrdersPage';
import MoreInformationPage from 'single-asset/pages/MoreInformationPage';
import { singleAssetRoutePaths } from 'single-asset/constants/singleAssetConstants';
import PushChartComparison from 'single-asset/components/PushChartComparison';
import EmissionsCalculatorPage from 'single-asset/pages/EmissionsCalculatorPage';
import DecoderPage from 'single-asset/pages/DecoderPage';
import NotesPage from 'single-asset/pages/NotesPage';

const AssetRoutes = ({
  enterpriseObject, asset, unit, externalUser,
}) => (
  <Routes>
    <Route
      exact
      path="/"
      element={(
        <TelemetryPage
          asset={asset}
          unit={unit}
          enterpriseObject={enterpriseObject}
        />
      )}
    />
    <Route
      exact
      path={singleAssetRoutePaths.EVENTS}
      element={<EventsPage asset={asset} />}
    />
    <Route
      exact
      path={singleAssetRoutePaths.WORK_ORDERS}
      element={<WorkOrdersPage asset={asset} />}
    />
    <Route
      exact
      path={singleAssetRoutePaths.DECODER}
      element={<DecoderPage orgId={asset.org_id} />}
    />
    <Route
      exact
      path={singleAssetRoutePaths.EMISSIONS_CALC}
      element={(
        <EmissionsCalculatorPage
          assetId={asset?.asset_id}
          assetName={asset?.asset_name}
        />
      )}
    />
    <Route
      exact
      path={singleAssetRoutePaths.NOTES}
      element={(
        <NotesPage
          orgId={asset?.org_id}
          externalUser={externalUser}
          assetName={asset?.asset_name}
        />
      )}
    />
    <Route
      exact
      path={singleAssetRoutePaths.MORE_INFORMATION}
      element={<MoreInformationPage asset={asset} />}
    />
    <Route
      exact
      element={<DigitalTwin />}
      path={singleAssetRoutePaths.DIGITAL_TWIN}
    />
    <Route
      exact
      path="push-comparison"
      element={<PushChartComparison />}
    />
  </Routes>
);

export default AssetRoutes;
