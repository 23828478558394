import React from 'react';
import {
  UndoRedo,
  MDXEditor,
  toolbarPlugin,
  headingsPlugin,
  BoldItalicUnderlineToggles,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

import './Note.scss';

interface NoteProps {
  markdown: string,
  handleMarkdown: React.Dispatch<React.SetStateAction<string>>
}

const Note: React.FC<NoteProps> = ({ markdown, handleMarkdown }) => (
  <div className="unit-note">
    <MDXEditor
      markdown={markdown}
      placeholder="Write a note..."
      onChange={(e) => handleMarkdown(e)}
      plugins={[
        headingsPlugin(),
        toolbarPlugin({
          toolbarClassName: 'unit-note-toolbar',
          toolbarContents: () => (
            <>
              <UndoRedo />
              <BoldItalicUnderlineToggles />
            </>
          ),
        }),
      ]}
    />
  </div>
);

export default Note;
