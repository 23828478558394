export const AXIL_API = 'https://api.axil.ai';
export const FLAXIL_API = 'https://api.flaxil.com';
export const AXIL_API_DEV = 'https://dev-api.axil.ai';
export const FLAXIL_API_DEV = 'https://dev-flaxil.api.axil.ai';

export const WORK_ORDERS_API = 'https://dsd-dev.flogistix.com';
export const WORK_ORDERS_PRINT_API = 'https://api2.flogistix.com';

export const URL_PREFIX = process.env.REACT_APP_BUILD_ENV === 'production'
  ? ''
  : 'dev-';
